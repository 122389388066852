export function Footer() {
  return (
    <footer className='bg-gray-900 text-white py-12'>
      <div className='container mx-auto px-6'>
        <div className='flex justify-between items-center'>
          <div className='flex items-center'>
            <h1 className='ml-3 text-2xl font-bold'>
              <img
                src='./logo.png'
                style={{ width: '100px' }}
                alt='GO GP Logo'
              />
            </h1>
          </div>
          <div className='space-x-6'>
            <a className='hover:underline' href='/#home'>
              {' '}
              Accueil{' '}
            </a>
            <a className='hover:underline' href='/about-us'>
              Qui sommes nous?
            </a>
            <a className='hover:underline' href='/#feature'>
              {' '}
              Fonctionnalités{' '}
            </a>
            <a className='hover:underline' href='/#demo'>
              {' '}
              Démo{' '}
            </a>
            <a className='hover:underline' href='/#faq'>
              {' '}
              Faq{' '}
            </a>
            <a className='hover:underline' href='/#contact'>
              {' '}
              Contact{' '}
            </a>
            <a className='hover:underline' href='/cgu'>
              {' '}
              CGU{' '}
            </a>
          </div>
        </div>
        <div className='mt-6 text-center'>
          <p>© 2024 GO GP</p>
          <div className='flex justify-center space-x-4 mt-4'>
            <a
              className='text-white'
              href='https://www.facebook.com/share/4csTzjov2KKpesGh'
              target='_blank'
            >
              <i className='fab fa-facebook-f'> </i>
            </a>
            <a
              className='text-white'
              href='https://www.tiktok.com/@gogp_off?_t=8puJFaykmkj&_r=1'
              target='_blank'
            >
              <i className='fab fa-tiktok'> </i>
            </a>
            <a className='text-white' href='#'>
              <i className='fab fa-twitter'> </i>
            </a>
            <a className='text-white' href='#'>
              <i className='fab fa-instagram'> </i>
            </a>
            <a className='text-white' href='#'>
              <i className='fab fa-linkedin-in'> </i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
