export function AboutUs() {
  return (
    <>
      <div className='max-w-2xl mx-auto mt-10 mb-10 p-4'>
        <h1 className='text-2xl font-bold mb-8'>Qui sommes nous ?</h1>

        <p className='text-sm leading-6 mb-4'>
          Découvrez GoGp, une plateforme innovante qui connecte les expéditeurs
          de colis avec des voyageurs disposant d’espace libre dans leurs
          bagages. Ce concept repose sur les "GP" (Gratis Passengers ou Gratuité
          Partielle), un système initialement réservé aux employés des
          compagnies aériennes et à leurs proches, leur permettant de voyager à
          moindre coût ou gratuitement. Historiquement, les "GP" étaient
          utilisés par ces voyageurs pour transporter des colis informellement
          pour des proches ou des tiers, en échange d'une petite compensation.
        </p>

        <p className='text-sm leading-6 mb-4'>
          GoGp formalise cette pratique en créant un espace sécurisé où les
          expéditeurs peuvent facilement trouver des voyageurs prêts à
          rentabiliser l'espace inutilisé dans leurs bagages. Que vous
          souhaitiez envoyer un cadeau spécial ou expédier un colis à
          l'international, GoGp vous offre une solution abordable, sûre et
          pratique.
        </p>
        <p className='text-sm leading-6 mb-4'>
          Économisez sur les frais de livraison en profitant de cette économie
          collaborative. Grâce à GoGp, chaque espace inutilisé devient une
          opportunité.
        </p>
        <p className='text-sm leading-6 mb-4'>
          GoGp transforme la façon dont vous expédiez vos colis, en vous
          assurant simplicité, sécurité et flexibilité. Téléchargez maintenant
          et rejoignez une communauté de voyageurs et d'expéditeurs qui font la
          différence !
        </p>
      </div>
    </>
  );
}
